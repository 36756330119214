<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  // metaInfo: {
  //   title: 'About Us',
  // },
  // head: {
  //   title: 'actions',
  // },
  created() {
    document.title = 'Портфели'
  },
}
</script>
